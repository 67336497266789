<template>
  <div class="setting world tips">
      <div class="content">
        <h3>
          {{form.type == 1?'解绑':'注销'}}成功！
        </h3>
        <h3 v-if="form.type == 1">手机号{{ form.phone }}已与{{form.company}}解绑</h3>
        <h3>
          <span>{{ time }}s</span>后跳转至本公司修改手机号页面
        </h3>
        <router-link to="/enterprise/centre/setting/phone">
          立即跳转至上述页面>>
        </router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: "tips",
  data(){
    return{
      time:5,
      form:{
        type:'1',
        phone:'',
        company:'',
      }
    }
  },
  methods:{

  },
  mounted() {
    setInterval(() =>{
      this.time--;
      if(this.time === 0){
        this.$router.push('/enterprise/centre/setting/phone')
      }
    },1000)
  },
  created() {
    let router = this.$route.query;
    if(router.type){
      this.form.type = router.type;
      this.form.phone = router.phone;
      this.form.company = router.name;
    }
  }
}
</script>

<style scoped lang="less">
@import "~assets/css/setting.less";
.tips{
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h3{
    font-size: 16px;
    line-height: 32px;
    span{
      color: #28A46D;
    }
  }
  a{
    color: #28A46D;
  }
}
</style>